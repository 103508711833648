<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="content">
          <h2>Uh, oh, we got disconnected!</h2>
          <p>We're having trouble with the network connection.</p>
          <p>Please make sure you have a strong cell or WiFi connection and then try again.</p>
          <button 
            @click="go()"
            class="button"
          >
            Go Back 
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    go() {
     this.$router.go(-1);
    },
  }
}
</script>
